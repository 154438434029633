import React from 'react';
import './Post.scss';

const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
    const day = parseInt(dateParts[2]);
  
    const formattedDate = new Date(year, month, day);
  
    // Format the date to 'MMM DD, YYYY'
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedString = formattedDate.toLocaleDateString('en-US', options);
  
    return formattedString;
}

const Post = ({ title, publish_date, description, images }) => {
  return (
    <div className="news-item">
        <div className="collage">
            {images.map((image, index) => (
                <div className={`image${index + 1}`}><img src={`${image}`} alt="News Related Images" /></div>
            ))}
        </div>

        <div className="info">
            <p className="title">{title}</p>
            <p className="date">{formatDate(publish_date)}</p>

            {description.map((c) => (
                <p className="content">{c}</p>
            ))}
        </div>
    </div>
  );
}

export default Post;