import React from 'react'
import './SectionTitle.scss';

const SectionTitle = (props) => {
    const { title, description, type} = props;
    return (
        <div id='sectiontitle' className={type ? type : ""}>
            <p className="title">{title}</p>
           
            <p className="description">{description}</p>
            
        </div>
    )
}

export default SectionTitle