import React, {useState, useEffect} from 'react';
import { storage } from '../../conf/Firebase';
import { ref, getDownloadURL, listAll } from 'firebase/storage';
import LightGallery from 'lightgallery/react';
import './Gallery.scss';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { useLocation } from 'react-router-dom';


function useScrollToTop() {
  const location = useLocation(); // This hook returns the location object that represents the current URL.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Reacting to changes in pathname to reset scroll
}
export default function GalleryComponent() {

    const [images, setImages] = useState([]);
    
    useScrollToTop()
    useEffect(() => {
          const getImages = async () => {
              try {
                  const imagesRef = ref(storage, 'gallery/');
                  const imageList = await listAll(imagesRef);
                  const imageUrls = await Promise.all(imageList.items.map((item) => getDownloadURL(item)));
                  setImages(imageUrls);
              } catch (e) {
                  console.error('Error fetching projects:', e);
              }
          }

          getImages();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    return (
        <div className="container gallery">
            <div className="row">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >   
                {images.map((image,index) => (
                    <a href={`${image}`} key={index}>
                        <img alt="" src={`${image}`}/>
                    </a>
                ))}
                ...
            </LightGallery>
            </div>
        </div>
    );
}

// const images = [
//     {url: '1.jpeg'},
//     {url: '2.jpeg'},
//     {url: '3.jpeg'},
//     {url: '4.jpeg'},
//     {url: '5.jpeg'},
//     {url: '6.jpeg'},
//     {url: '7.jpeg'},
//     {url: '8.jpeg'},
//     {url: '9.jpeg'},
//     {url: '10.jpeg'},
//     {url: '11.jpeg'},
//     {url: '12.jpeg'},
//     {url: '13.jpeg'},
//     {url: '14.jpeg'},
//     {url: '15.jpeg'},
//     {url: '16.jpeg'}
// ]
