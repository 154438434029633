import React, { Component } from 'react';
import './Navbar.scss';

class Navbar extends Component {
  state = {
    clicked: false,
    scrolled: false,
    screenWidth: window.innerWidth
  };

  handleclick = () => {
    this.setState({ clicked: !this.state.clicked });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    return (
      <nav className={this.state.scrolled ? "scrolled" : ""}>
        <a href="/">
          {this.state.screenWidth <= 796 ? (
            <img src={'/icons/logo-mini-updated.svg'} alt='logo' />
          ) : (
            <img src={'/icons/logo-updated.svg'} alt='logo' />
          )}
        </a>
        <div>
          <ul id='navlinks' className={this.state.clicked ? "active" : ""}>
            <CustomLink to="/" >Home</CustomLink>
            <li>
              <span>Youth Leadership</span>
              <ul className="dropdown">
                <CustomLink to="/leadershipacademy">Leadership Academy</CustomLink>
                <CustomLink to="/sustainableimpactprojects">Sustainable Impact Projects</CustomLink>
              </ul>
            </li>
            <CustomLink to="/communityresilience">Resilient Communities</CustomLink>
            <CustomLink to="/org-support">Organizational Support</CustomLink>
            <CustomLink to="/gallery">Gallery</CustomLink>
            <CustomLink to="/about">About Us</CustomLink>
            <CustomLink to="/contact">Contact Us</CustomLink>
          </ul>
        </div>
        <div id="mobile" onClick={this.handleclick}>
          <i id="bar" className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    );
  }
}

function CustomLink({ to, children }) {
  // Use <a> tags for navigation
  return (
    <li>
      <a href={to} className="nav-link">
        {children}
      </a>
    </li>
  );
}

export default Navbar;
