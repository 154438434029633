import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCou0NwtSFMrV3vXjdCUd9QbOycTOxa2lY",
    authDomain: "globalll.firebaseapp.com",
    projectId: "globalll",
    storageBucket: "globalll.appspot.com",
    messagingSenderId: "571425073776",
    appId: "1:571425073776:web:4bea5c9b5ab9a69f7662b5"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);