import React from 'react';
import './Button.scss';

const Button = (props) => {
  const { label, onClick, type} = props;

  return (
    <button className={`btn ${type}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
