import React, { useState, useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import './presentation.scss';

const SlideImages = Array.from({ length: 49 }, (_, i) => `/images/SIP/Slide${i+1}.PNG`);
const slideshowProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: true
  
};

export default function PresentationSlider() {
  const [arrows, setArrows] = useState(true);

  useEffect(() => {
    function handleScroll() {
      // Get the position of the navbar
      const navbar = document.getElementById('nav');
      if (!navbar) return; // Return if navbar element does not exist
      const navbarBottom = navbar.getBoundingClientRect().bottom;

      // Get the position of the slideshow arrows
      const slideshowArrows = document.querySelector('.react-slideshow-arrow');
      if (!slideshowArrows) return; // Return if slideshow arrows do not exist
      const arrowsTop = slideshowArrows.getBoundingClientRect().top;

      // Set arrows property based on the overlap
      setArrows(arrowsTop > navbarBottom);
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='container'>
      <div className='slide-container w-50'>
        <Slide {...slideshowProperties} arrows={arrows}>
          {SlideImages.map((image, index) => (
            <div key={index} className='w-50'>
              <div className='slides'>
                <img src={image} alt={`Slide ${index + 1}`} className="w-100" />
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
}
