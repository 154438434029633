import React,{useEffect} from 'react';
import './AboutUs.scss';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import VideoPlayer from '../components/VideoPlayer/videoPlayer'
import { useLocation } from 'react-router-dom';
import UpdateBodyBackground from '../utils/UpdateBackground';
import {Helmet} from 'react-helmet'
function useScrollToTop() {
    const location = useLocation(); // This hook returns the location object that represents the current URL.
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]); // Reacting to changes in pathname to reset scroll
  }
export const AboutUs = () => {

    useScrollToTop();



    UpdateBodyBackground()
  return (
    <div className='page aboutus' >
        <Helmet>
            <title>About Us - Global Learning Lab</title>
            <meta
          name="description"
          content="Discover the Global Learning Lab's commitment to empowering young leaders globally through sustainable 
                    solutions and impactful leadership programs."
          
        />
            <link rel='canonical' href='https://globallearninglab.org/about'/>

        </Helmet>
        <HeroSection
            title='Our Collective Journey'
            description=''//'Explore diverse realms of knowledge in our collaborative sanctuary, where boundless curiosity fuels a shared journey towards limitless understanding.'
        />

         {/* <SectionTitle 
            title='Want to Know More About Us' 
            description='The Leadership Academy and related Leadership Toolkit exist to provide individuals, groups of
            people, and communities With the critical knowledge, skills and experiences required for
            positive impact and success. The solution engages young leaders early in the creation Of
            sustainable solutions. Our work has had positively impacted over 10,000 young leaders across
            Asia historically and now is being shared around the world. Youth understanding and
            engagement is key to our future. We acknowledge the inspiration from the Center for Creative
            Leadership (CCL), Habitat for Humanity APAC along With the framework Of the IJN Sustainable
            Development Goals in shaping Sustainable Impact Projects( SIPS).
            Most of all, we thank our solution partners and providers and the young leaders who have and
            will participate in the program.'
        /> */}
        <div className="container about">
            <div class="about" style={{marginTop:"90px", marginBottom:"80px"}}>
                <div class="left">
                    <div class="image-1">
                        <img src="/images/about-1.png" alt="" id="image1"/>
                    </div>
                    <div class="image-2">
                        <img src="/images/about-2-new.png" alt="" id="image2" />
                    </div>
                </div>
                <div class="right">
                    <h1>About GLL</h1>
                    <p>
                    The Global Learning Lab was first established in India with a commitment to learning for all.
            Global Learning Lab was incubated in Hawaii with the Hawaii Executive Collaborative and Claremont McKenna
            College (USA) to prepare young leaders for thoughtful and productive lives. Currently, the
            Global Learning Lab has projects in South Asia, Hawaii, and the Balkan region. <br></br> <br></br> 

                    The Leadership Academy and related Leadership Toolkit exist to provide individuals, groups of
                    people, and communities with the critical knowledge, skills and experiences required for
                    positive impact and success. The solution engages young leaders early in the creation of
                    sustainable solutions. Our work has  positively impacted over 10,000 young leaders across
                    Asia historically and now is being shared around the world.<br/> Youth understanding and
                    engagement is key to our future. We acknowledge the inspiration from the Center for Creative
                    Leadership (CCL), Habitat for Humanity APAC along with the framework of the UN Sustainable
                    Development Goals in shaping Sustainable Impact Projects( SIPS).
                    </p>
                    <p class="bold">
                    Most Of all, we thank our solution partners and providers and the young leaders who have and
                    will participate in the program.
                    </p>
                </div>
            </div>
        </div>
       

       
    </div>
  )
}

export default AboutUs;