import React,{useEffect} from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import Goal from '../components/Goal/Goal';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper/modules';
import './OrganizationalSupport.scss';
import { Helmet } from 'react-helmet';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/swiper-bundle.css";
import { update } from 'firebase/database';
import UpdateBodyBackground from '../utils/UpdateBackground';

export const OrganizationalSupport = () => {
  UpdateBodyBackground();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cards = [
    {
      title: 'The Need',
      description: 'Organizations are created to achieve outcomes. Overtime, many of the processes and systems to support people and outcomes become less effective and need to be reviewed, modified and often re-defined. There is a need, on occasion, for third parties to support leadership create higher levels of direction, alignment and commitment between various stakeholders.',
      image: 'need3'
    },
    {              
      title: 'What We Know',
      description: 'Organizations succeed by aligning the needs of various stakeholders with the vision, mission, and strategy. Successful organizations build a learning culture with high levels of communication and collaboration between members. Often, organizations need support in building the conditions wherein individuals and teams flourish and can grow and innovate. Organizations that support talent and grow leaders have the best chance to have positive a impact and outcomes.',
      image: 'know3'
    },
    {              
      title: 'What We Do',
      description: 'We diagnose, engage, evaluate and provide feedback. The team at the Global Learning Lab has decades of experience working across the organizational spectrum including private and publicly traded organizations, NGOs/lNGOs/Civil Society, family owned, and family influenced conglomerates and businesses. We are ready to provide proven solutions that support organizations and team members improve the well-being of all stakeholders and in the regions and communities in which they operate.',
      image: 'do3'
    }
  ]

  return (
    <div className='page yettocome'>
      <Helmet>
         <title>Organizational Support - Global Learning Lab</title>
         <meta
            name="description"
            content="Discover how Global Learning Lab enhances organizational effectiveness and stakeholder commitment through strategic solutions and expert support."
  />
      <link rel='canonical' href='https://globallearninglab.org/org-support'/>
</Helmet>
        <HeroSection
            title="Organizational Support"
            description=''//'Join us behind the curtain, where ideas sprout, and dreams take shape. Unveiling a masterpiece in the making – your future begins with our underdevelopment symphony.'
        />

<div id='goal' className="container goals">
          <div className="goals">
            {cards.map((card, index) => (
              <Goal
                title={card.title}
                description={card.description}
                image={card.image}
              />
            ))}
           </div>
        </div>

        <div className="swiper-mobile-container org-sup-route">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate:0,
              stretch:0,
              depth:100,
              modifier:2.5
            }}
            modules={[Pagination, Navigation]}
            // spaceBetween={50}
            scrollbar={{ draggable: true }}
            pagination={{el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            className='swiper-container'
            style={{
              "--swiper-pagination-color": "#357F90"}}
          >
            {cards.map((card, index) => (
              <SwiperSlide className='swiper-slide'>
                <Goal
                  title={card.title}
                  description={card.description}
                  image={card.image}
                />
              </SwiperSlide>
              ))}

            <div className="slide-controller">
              <div className="swiper-button-next" style={{ color: '#357F90' }}></div>
              <div className="swiper-button-prev" style={{ color: '#357F90' }}></div>
              <div className="swiper-pagination">

              </div>
            </div>
          </Swiper>
        </div>

    </div>
  )
}
export default OrganizationalSupport;