import React from 'react';
import './Foundation.scss';

const Foundation = (props) => {
    const { title, description, image, path} = props;

    return (
        <div className='foundation'>
                <a href={path}>
                    <img src={'/icons/'+image+'.svg'} alt='goal' />
                    <p className="title">{title}</p>
                    {/* <p className="title">{lastWord}</p> */}
                    <p className="description">{description}</p>
                </a>
            </div>
    );
}

export default Foundation;