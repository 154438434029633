import React from 'react';
import './Footer.scss';

function Footer() {

  return (
    <footer>
      <div id="upper-footer">
        <div className="left">
          <div className="footer-col">
            <p>Services</p>
            <ul>
              <li><a href="/leadershipacademy" onClick={() => window.scrollTo(0, 0)}>Leadership Academy</a></li>
              
              <li><a href="/sustainableimpactprojects" onClick={() => window.scrollTo(0, 0)}>Sustainable Impact Projects</a></li>
              
              <li><a href="/communityresilience" onClick={() => window.scrollTo(0, 0)}>Resilient Community</a></li>
              
              <li><a href="/org-support" onClick={() => window.scrollTo(0, 0)}>Organizational Support</a></li>
            </ul>
          </div>
        </div>

        <div className="right">
          <div className="footer-col">
            <ul>
              <li><a href="/about" onClick={() => window.scrollTo(0, 0)}>About Us</a></li>
              <li><a href="/gallery" onClick={() => window.scrollTo(0, 0)}>Gallery</a></li>
            </ul>
          </div>
          
          <div className="footer-col">
            <ul>
              <li><a href="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</a></li>
              <li><a href="mailto:info@globallearninglab.org">E-mail: info@globallearninglab.org</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div id="lower-footer">
        <p>&copy; 2023 GLL. All Rights Reserved.</p>
        <div className="social">
          <a href="https://www.instagram.com/globallearninglab?igsh=MThrNXY1ZjNoYW1zYw%3D%3D&utm_source=qr"><i className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com/company/global-learning-lab/"><i className="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
