import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../conf/Firebase';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Research from '../components/Research/Research';
import './Researches.scss';
import Goal from '../components/Goal/Goal';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper/modules';
import { Helmet } from 'react-helmet';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/swiper-bundle.css";
import UpdateBodyBackground from '../utils/UpdateBackground';

const Researches = () => {

  UpdateBodyBackground();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cards = [
    {
      title: 'The Need',
      description: 'Rural, Suburban, and Urban communities are faced with increased pressure for resources. These pressures have a direct impact on families and individuals living within these areas. This results in increased economic and social stress. A single entity alone will have difficulty in finding sustainable solutions. An approach to identify backbone organization(s) and facilitate collective action will result in higher levels of resilience.',
      image: 'need2'
    },
    {              
      title: 'What We Know',
      description: 'Successful and sustained community action requires an understanding of various types of human capital including financial capital, built capital, political capital, social capital, cultural capital, and natural capital. In addition to diagnosing the amount of capital that exists, the identification of a "backbone" organization is key. Backbone organizations serve to coordinate and support the efforts of the various individuals, groups and organizations focused on an outcome across a geographic boundary.',
      image: 'know2'
    },
    {              
      title: 'What We Do',
      description: 'The foundation for any successful community is learning, leadership, and collaboration. GLL believes that engaging young leaders these efforts is one of the keys to sustainability. We support backbone organizations and communities with this process.',
      image: 'do2'
    }
  ]

  const [researches, setResearches] = useState([]);
  const researchesCollectionRef = collection(db, 'researches');


  useEffect(() => {
      const getProjects = async () => {
          try {
              // Create a query with orderBy to get data in descending order based on 'edited_date'
              const q = query(researchesCollectionRef, orderBy('publish_date', 'desc'));
            
            // Execute the query
              const data = await getDocs(q);
              // const data = await getDocs(researchesCollectionRef);
              setResearches(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
              // setRefresh(false);
          } catch (e) {
              console.error('Error fetching projects:', e);
          }
      }

      getProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='page projects'>
      <Helmet>
        <title>Community Resilience - Global Learning Lab</title>
        <meta
         name="description"
         content="Discover how Global Learning Lab fosters community resilience and sustainability by engaging young leaders and supporting backbone organizations across diverse communities."
        />
        <link rel='canonical' href='https://globallearninglab.org/communityresilience'/>
      </Helmet>

        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Community Resilience and Sustainability'
        description=''
        />


        {/* ------------- Projects Section -------------*/}
        


        
         <div id='goal' className="container goals">
          <div className="goals">
            {cards.map((card, index) => (
              <Goal
                title={card.title}
                description={card.description}
                image={card.image}
              />
            ))}
           </div>
        </div>

        <div className="swiper-mobile-container research-route">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate:0,
              stretch:0,
              depth:100,
              modifier:2.5
            }}
            modules={[Pagination, Navigation]}
            // spaceBetween={50}
            scrollbar={{ draggable: true }}
            pagination={{el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            className='swiper-container'
            style={{
              "--swiper-pagination-color": "#357F90"}}
          >
            {cards.map((card, index) => (
              <SwiperSlide className='swiper-slide'>
                <Goal
                  title={card.title}
                  description={card.description}
                  image={card.image}
                />
              </SwiperSlide>
              ))}

            <div className="slide-controller">
              <div className="swiper-button-next" style={{ color: '#357F90' }}></div>
              <div className="swiper-button-prev" style={{ color: '#357F90' }}></div>
              <div className="swiper-pagination">

              </div>
            </div>
          </Swiper>
        </div>


        {/* <SectionTitle 
          title='Checkout Our Recent Researches' 
          description='Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
          power of global learning on a journey without boundaries.'
        /> */}
        {/* <div className="container researches">
          <div className="column">
            {researches.map((research) => (
              <Research
              title={research.title}
              description={research.description}
              image={research.image}
              paper={research.paper}
              publish_date={research.publish_date}
              authors={research.authors}
              />
            ))}
          </div>
        </div> */}
        
    </div>
  );
}

export default Researches;

// const researches = [
//   {
//     title: 'Digital Literacy in Education',
//     description: "This research explores the impact of digital literacy on students' academic success and examines effective strategies for integrating technology into education. It aims to enhance the quality of digital learning experiences.",
//     date: new Date(),
//     image: 'research1.png',
//     paperLink: '',
//     authors: [
//       'Dr. Sarah Martinez',
//       'Professor James Lee',
//       'Researcher Emily Johnson'
//     ]
//   },
//   {
//     title: 'Cultural Diversity in Online Learning',
//     description: "This study investigates the challenges and opportunities presented by cultural diversity in virtual classrooms. It seeks to identify best practices for promoting inclusivity and cross-cultural collaboration in online education.",
//     date: new Date(),
//     image: 'research2.png',
//     paperLink: '',
//     authors: [
//       'Dr. Maria Rodriguez',
//       'Professor Wei Chen'
//     ]
//   },
//   {
//     title: 'AI-Powered Personalized Learning',
//     description: "This research delves into the application of artificial intelligence to customize and optimize educational content for individual learners. The goal is to improve student engagement, retention, and overall learning outcomes.",
//     date: new Date(),
//     image: 'research3.png',
//     paperLink: '',
//     authors: [
//       'Dr. Lisa Anderson',
//       'Professor David Smith',
//       'Researcher Priya Patel'
//     ]
//   }
// ]

