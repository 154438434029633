import './App.css';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './routes/Home';
import Repository from './routes/Repository';
import Projects from './routes/Projects';
import Researches from './routes/Researches';
import Gallery from './routes/Gallery';
import News from './routes/News';
import Contact from './routes/Contact';
import AboutUs from './routes/AboutUs';
import YetToCome, { OrganizationalSupport } from './routes/OrganizationalSupport';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (

    
    <div className="App">
        
      {/* ------------- NavBar -------------*/}
   
      <Navbar/>
   
      {/* ------------- Body -------------*/}
      <Routes>
        
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/sustainableimpactprojects" element={<Repository />}/>
        <Route path="/leadershipacademy" element={<Projects/>}/>
        <Route path="/communityresilience" element={<Researches/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/org-support" element={<OrganizationalSupport/>}/>
      </Routes>

      {/* ------------- Footer -------------*/}
        
      <Footer/>
    </div>
  );
}

export default App;
