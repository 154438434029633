import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../conf/Firebase';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import './News.scss';
import Post from '../components/Post/Post';
import UpdateBodyBackground from '../utils/UpdateBackground';
import {Helmet} from 'react-helmet'

const News = () => {

  const [news, setNews] = useState([]);
  const newsCollectionRef = collection(db, 'news');

  UpdateBodyBackground();

  useEffect(() => {

      const getProjects = async () => {
          try {
              // Create a query with orderBy to get data in descending order based on 'edited_date'
              const q = query(newsCollectionRef, orderBy('publish_date', 'desc'));
            
            // Execute the query
              const data = await getDocs(q);
              // const data = await getDocs(newsCollectionRef);
              setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
              // setRefresh(false);
          } catch (e) {
              console.error('Error fetching projects:', e);
          }
      }

      getProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='page projects'>
      <Helmet>
        <meta name='robots' content= 'noindex'/>    
      </Helmet>

        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Our Latest News'
        description='Explore our impact featured projects, each designed to connect, educate, and inspire. 
        Discover initiatives that empower global citizens, romote cultural exchange, 
        and drive cross-cultural understanding.'
        />


        {/* ------------- Projects Section -------------*/}
        <SectionTitle 
          title='Stay Informed with Our Latest News' 
          description='Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
          power of global learning on a journey without boundaries.'
        />
        <div className="container news">
          
          <div class="news-list">
            {news.map((newsItem) => (
              <Post
              title={newsItem.title}
              description={newsItem.description}
              images={newsItem.images}
              publish_date={newsItem.publish_date}
               />
            ))}
          </div>
        </div>
        
    </div>
  );
}

export default News;

// const news = [
//   {
//     title: 'New Study Reveals Surprising Benefits of Online Learning',
//     date: new Date(),
//     contents: [
//       "In a groundbreaking research study conducted by our global learning lab, the conventional understanding of online learning has been challenged as the findings unveiled unexpected and substantial benefits. The study, spanning over two years and involving a diverse group of students across multiple age groups and disciplines, aimed to assess the effectiveness of online education in comparison to traditional classroom settings.",

//       "The results were nothing short of astonishing. Contrary to the prevalent skepticism surrounding online education, the study revealed that students engaged in virtual learning not only achieved academic results comparable to their classroom counterparts but also exhibited significantly higher levels of self-motivation and time management skills. The flexibility and personalized learning options offered by online platforms allowed students to tailor their educational experiences, fostering a deeper sense of ownership over their learning journey."
//     ],
//     images: [
//       'news1-1.png',
//       'news1-2.png',
//       'news1-3.png',
//       'news1-4.png',
//     ]
//   },
//   {
//     title: 'New Study Reveals Surprising Benefits of Online Learning',
//     date: new Date(),
//     contents: [
//       "In a groundbreaking research study conducted by our global learning lab, the conventional understanding of online learning has been challenged as the findings unveiled unexpected and substantial benefits. The study, spanning over two years and involving a diverse group of students across multiple age groups and disciplines, aimed to assess the effectiveness of online education in comparison to traditional classroom settings.",

//       "The results were nothing short of astonishing. Contrary to the prevalent skepticism surrounding online education, the study revealed that students engaged in virtual learning not only achieved academic results comparable to their classroom counterparts but also exhibited significantly higher levels of self-motivation and time management skills. The flexibility and personalized learning options offered by online platforms allowed students to tailor their educational experiences, fostering a deeper sense of ownership over their learning journey."
//     ],
//     images: [
//       'news1-1.png',
//       'news1-2.png',
//       'news1-3.png',
//       'news1-4.png',
//     ]
//   }
// ]