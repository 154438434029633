import React, { useState } from 'react';
import './Input.scss';

export const CustomTextInput = ({ label, name, value, onChange }) => {
  return (
    <div className="input-box">
      <input type="text" required name={name} value={value} onChange={onChange} />
      <span>{label}</span>
    </div>
  );
};

export const CustomTextAreaInput = ({ label, name, value, onChange }) => {
  const [textAreaHeight, setTextAreaHeight] = useState('auto');

  const handleTextareaChange = (e) => {
    let scHeight = e.target.scrollHeight;
    setTextAreaHeight('auto');
    setTextAreaHeight(`${scHeight}px`);
    onChange(e); // Call the onChange prop to update the form data
  };

  return (
    <div className="input-box">
      <textarea
        required
        name={name}
        value={value}
        style={{ height: textAreaHeight }}
        onChange={handleTextareaChange}
      />
      <span>{label}</span>
    </div>
  );
};

export default CustomTextInput;
