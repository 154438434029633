import React, { useState, useEffect, useRef } from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
//import Goal from '../components/Goal/Goal';
import Foundation from '../components/Foundation/Foundation';
import './Home.scss';
import TestimonialSlider from '../components/TestimonialSlider/TestimonialSlider';
import PartnersSlider from '../components/PartnerSlider/PartnersSlider';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper/modules';
import {Helmet} from 'react-helmet'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/swiper-bundle.css";
import UpdateBodyBackground from '../utils/UpdateBackground';


const Home = () => {

  UpdateBodyBackground();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cards = [
    {
      title: 'Youth Leadership',
      description: 'Investing in high-potential young leaders.',
      image: 'youth',
      path: '/leadershipacademy'
    },
    {
      title: 'Resilient Community',
      description: 'Forming caring, passionate and impactful social communities and formal organizations.',
      image: 'community',
      path: '/communityresilience'
    },
    {
      title: 'Organizational Support',
      description: 'Enhancing organizations through strategic alignment for improved performance and growth.',
      image: 'organization',
      path: '/org-support'
    }
  ]

  return (
    <div className='page'>
        
    <Helmet>
        <meta
        name="description"
        content="Empowering leaders worldwide to create opportunities and drive lasting social and economic impact through
         youth leadership and community support."
        />
        <link rel='canonical' href='href="https://globallearninglab.org/'/>
      
    </Helmet>

        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Impact Now and For The Future'
        description=''
        />

        {/* ------------- Foundation Section -------------*/}

        <SectionTitle 
          title='' //
          description="The Global Learning Lab empowers local leaders, groups and organizations across the world to unlock new opportunities for their communities. By investing in high-potential youth and emerging leaders we foster fresh perspectives, novel innovations and implementation expertise. Our strategies are formulated to drive lasting economic and social impact that will benefit our shared future."
        />
        <div id='journey' className="container pillar">
          <div id='pillars-container' className="pillars">
            {cards.map((card, index) => (
              <Foundation
                title={card.title}
                description={card.description}
                image={card.image}
                path={card.path}
              />
            ))}
          </div>
        </div>

        <div className="swiper-mobile-container home-route">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate:0,
              stretch:0,
              depth:100,
              modifier:2.5
            }}
            modules={[Pagination, Navigation]}
            scrollbar={{ draggable: true }}
            pagination={{el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            className='swiper-container'
            style={{
              "--swiper-pagination-color": "#357F90"}}
          >
            {cards.map((card, index) => (
              <SwiperSlide className='swiper-slide'>
                <Foundation
                  title={card.title}
                  description={card.description}
                  image={card.image}
                  path={card.path}
                />
              </SwiperSlide>
              ))}

            <div className="slide-controller">
              <div className="swiper-button-next" style={{ color: '#357F90' }}></div>
              <div className="swiper-button-prev" style={{ color: '#357F90' }}></div>
              <div className="swiper-pagination">

              </div>
            </div>
          </Swiper>
        </div>
        {/* ------------- Goal Section -------------*/}

        {/* <SectionTitle 
          title='Our Global Learning Journey' 
          description='Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
          power of global learning on a journey without boundaries.'
        />
        <div className="container outcome youth-leadership">
          <div className="row">
            <img src={'/images/leadership.png'} alt='logo' className='image'/>
            <div className="outcomes">
              <p className="title">What Youth Leadership Does</p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants will be supported/sponsored by an organization as they carry out their sustainable development plan</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>The Leadership Academy requires a 4-day train-the-trainer program (TTT) that teaches future facilitators the leadership modules based on applied research, best practices and adapted content, while allowing them to reflect on how they would uniquely facilitate these modules. This TTT model allows for localized peer to peer training</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>After completing the TTT, youth leaders will be certified to train the 2-day Leadership Academy. Youth trainers can become Master Trainers, Country Trainers and Global Trainers</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id='goal' className="container goals">
          <div className="goals">
            <Goal
              title='The Need'
              description='Local organizations are supporting communities in need each and every day. How ever the need is greater than the hands and resources that can be applied. New solutions and fresh ideas from passionate young community members are needed to create impact in communities across the continent with fresh eyes and new ideas.'
              image='need'
            />
            <Goal
              title='What We Know'
              description='A thriving community is built upon a foundation of learning, leadership, and collaboration. We recognize that individual knowledge and experiences, when combined with social and community-based learning, are pivotal in achieving sustainability and resilience.'
              image='know'
            />
            <Goal
              title='What We Do'
              description='Our leadership academy is an integrated, multidimensional approach to creating sustainable communities, youth development, and community well-being. The program is designed to develop youth as citizen leaders through a 2-day program delivered peer to peer and a sustainable development project led by youth.'
              image='do'
            />
          </div>
        </div> */}


        {/* ------------- OUtcomes Section -------------*/}

        {/* <SectionTitle 
          type='primary'
          title='Our Outcomes' 
          description="Discover the tangible impact of our efforts, from transforming lives to building resilient communities. See how we're making a positive change in the world."
        /> */}
        {/* <div className="container outcome">
          <div className="row">
            <img src={'/images/image1.svg'} alt='logo' className='image'/>
            <div className="outcomes">
              <p className="title">Individual</p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants see themselves as leaders. When youth see themselves as 	leaders they are more likely to enga.</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants grow in their abilities to be a leader, particularly in regard to 	Leading Self, Leading with others, and .</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants see themselves as change agents and have concrete ideas 	</p>
                </li>
              </ul>
            </div>
          </div>

            <AnimatedImage src="/images/image2.svg" alt="logo" className="mobile image" />
          <div className="row">
            <img src={'/images/image2.svg'} alt='logo' className='mobile image'/>
            <div className="outcomes">
              <p className="title">Community</p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Better education</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Higher quality of life</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Healthier families</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>More sustainable communities</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Community becomes a model to inform the leadership and development field more broadly</p>
                </li>
              </ul>
            </div>
            <img src={'/images/image2.svg'} alt='logo' className='desktop image'/>
          </div>
        </div> */}


        {/* ------------- Testimonial -------------*/}

        {/* <SectionTitle
          title='See Whats Our Leaders Say' 
          description="Discover the stories and experiences shared by our valued customers. Their insights and feedback reflect the impact of our global learning initiatives, building a community of informed and engaged global citizens."
        />
        <TestimonialSlider/> */}


        {/* ------------- Goal Section -------------*/}
         
        

    </div>
  );
}


export default Home;