import React,{useEffect} from 'react';
import './HeroSection.scss';
// import Button from '../Button/Button';
import { useLocation } from 'react-router-dom';


function useScrollToTop() {
  const location = useLocation(); // This hook returns the location object that represents the current URL.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Reacting to changes in pathname to reset scroll
}
const HeroSection = (props) => {
  const {title, description } = props;
  // const handleClick = () => {
  //   // Your click event handler logic here
  // };
  useScrollToTop() 


  return (
    <div className="hero">
      <p className='heading'>{title}</p>
      <p className='description'>{description}</p>
      {/* <Button label="Explore More" onClick={handleClick} type="arrow" /> */}
    </div>
  );
}

export default HeroSection;