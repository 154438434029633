import React,{useEffect} from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import GalleryComponent from '../components/Gallery/Gallery';
import './Gallery.scss';
import { useLocation } from 'react-router-dom';
import UpdateBodyBackground from '../utils/UpdateBackground';
import { Helmet } from 'react-helmet';


function useScrollToTop() {
  const location = useLocation();
  UpdateBodyBackground(); // This hook returns the location object that represents the current URL.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Reacting to changes in pathname to reset scroll
}

const Gallery = () => {
  useScrollToTop()

  return (
    <div className='page projects'>

      <Helmet>
        <title>Gallery - Global Learning Lab</title>
        <meta
            name="description"
            content="Explore our Leadership Workshops Gallery featuring impactful images of workshops conducted by Global Learning Lab. See how we empower leaders through interactive and engaging learning experiences."
        />
        <link rel='canonical' href='https://globallearninglab.org/gallery'/>
      </Helmet>

        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Our Story'
        description=''//'Explore our impact featured projects, each designed to connect, educate, and inspire. 
        //Discover initiatives that empower global citizens, romote cultural exchange, 
        //and drive cross-cultural understanding.'
        />


        {/* ------------- Projects Section -------------*/}
        {/* <SectionTitle 
          title='Pictures That Tell Our Story' 
          description='Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
          power of global learning on a journey without boundaries.'
        /> */}
        <GalleryComponent/>
        
    </div>
  );
}

export default Gallery;