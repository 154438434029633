import React from 'react';
import './Goal.scss';

const Goal = (props) => {
    const { title, description, image} = props;
    const words = title.split(' ');
    const lastWord = words.pop();
    const restOfTheWords = words.join(' ')+' ';

    return (
        <div className='goal'>
            {/* <img src={'/icons/'+image+'.png'} alt='goal' /> */}
            <img src={'/images/'+image+'.png'} alt='goal' />
            <div className='goal-content'>
                <p className="title">{restOfTheWords}<span>{lastWord}</span></p>
                <p className="description">{description}</p>
            </div>
        </div>
    );
}

export default Goal;