import React, {useState, useEffect} from 'react';
import { collection, getDocs} from 'firebase/firestore';
import { db } from '../conf/Firebase';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Repo from '../components/Repository/Repo';
import './Repository.scss';
import CurrentProjects from '../components/CurrentProjects/CurrentProjects';
import PresentationSlider from '../components/PresentationSlider/PresentationSlider';
import UpdateBodyBackground from '../utils/UpdateBackground';
import { Helmet } from 'react-helmet';

const Repository = () => {
  UpdateBodyBackground();

  const [repositories, setRepositories] = useState([]);
  const repositoriesCollectionRef = collection(db, 'repositories');

  useEffect(() => {
      const getProjects = async () => {
          try {
              const data = await getDocs(repositoriesCollectionRef);
              setRepositories(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
              // setRefresh(false);
          } catch (e) {
              console.error('Error fetching projects:', e);
          }
      }

      getProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       <Helmet>
        <title>Sustainable Impact Projects - Global Learning Lab</title>
        <meta
          name="description"
          content="Explore our Sustainable Impact Projects across Macedonia, Hawaii, and Sri Lanka, aimed at fostering
                   community resilience and social impact through collaborative initiatives."
        />
        <link rel='canonical' href='https://globallearninglab.org/sustainableimpactprojects'/>
      </Helmet>
    <div className='page repository'>

        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Sustainable Impact Projects'
        description=''
        />


        {/* ------------- Repository Section -------------*/}
        <SectionTitle 
          title='Current Projects' 
          description=''//'Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
         // power of global learning on a journey without boundaries.'
        />
        {/* <div className="container repositories">
          <div class="grid-container">
            {repositories.map((r) => (
              <Repo title={r.title} description={r.description} image={r.image} downLink={r.download_link} githubLink={r.github_link}/>
            ))}
          </div>
        </div> */}
        
          <CurrentProjects/>
          

      
          
    </div>
    <SectionTitle title='Macedonia SIP Presentaion' type='head'/>
    <PresentationSlider></PresentationSlider>

    <SectionTitle title='SriLanka Visit' type='head'/>
    <div className="article-container">
    <img className='article' src={'/images/news/sl-visit.png'} alt='article' />
    </div>
    </div>

    
  );
}

export default Repository;

// const repositories = [
//   {
//     image: 'repository-1.png',
//     title: 'eLearning Platform',
//     description: 'An interactive online platform offering diverse courses',
//     downLink: 'https://github.com/'
//   },
//   {
//     image: 'repository-2.png',
//     title: 'Global Language Exchange',
//     description: 'Connects learners across cultures for language exchange',
//     downLink: 'https://github.com/'
//   },
//   {
//     image: 'repository-3.png',
//     title: 'Virtual Classroom',
//     description: 'Facilitates real-time, interactive online classes',
//     downLink: 'https://github.com/'
//   },
//   {
//     image: 'repository-4.png',
//     title: 'Learning Analytics Dashboard',
//     description: 'Provides data-driven insights into learner performance',
//     downLink: 'https://github.com/'
//   },
//   {
//     image: 'repository-5.png',
//     title: 'Online Assessment Toolkit',
//     description: 'Create, distribute, and evaluate assessments online',
//     downLink: 'https://github.com/'
//   },
//   {
//     image: 'repository-6.png',
//     title: 'Global Collaboration Hub',
//     description: 'A digital space for students and educators to collaborate',
//     downLink: 'https://github.com/'
//   },
// ]