import React from 'react';
import './pdfViewer.scss'

const pdf1="/Workbook_Macedonia_Final.pdf";
const pdf2="/Facilitator Manual-All Modules.pdf";
const src1 = '/thumbnail1.png';
const src2 = '/insetimage.png';


const PdfViewer = () => {


    
    const downloadFile=(url)=>{
        const filename= url.split('/').pop();
        const aTag =document.createElement("a");
        aTag.href=url;
        aTag.setAttribute('download',filename);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    return(<div class="flex-container">
    <div >
        <img src={src1}  alt="Thumbnail" class="thumbnailimage content" />
        <img src={src2} alt="Thumbnail" class="thumbnailimage content" />

    </div>
    {/* <div class="content">
        <h3>Leadership Academy Toolkit</h3>
        <button class="download-button" onClick={() => { downloadFile(pdf1); }}>
            Download
        </button>
    </div> */}
</div>
);

    
};

export default PdfViewer;
