import PresentationSlider from '../PresentationSlider/PresentationSlider';
import './CurrentProjects.scss';

const CurrentProjects = () => {

    const downloadFile = (url) => {
        const filename = url.split('/').pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute('download', filename);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    return (
        <div className='containers '>
            <div className='grid-containers'>
                <ul className='list'>
                    <li className='heading'>
                        Macedonia
                        <ul className='list content file'>
                            <li>
                                <div className='fileitem' onClick={() => downloadFile('/RCP Roma Anti Discrimination Campaign.pdf')}>
                                    - Anti-Discrimination against the Roma: work with the Mayor for the campaign
                                </div>
                            </li>
                            <div className='fileitem' onClick={()=>{downloadFile('/HFH Youth Ecological Club.pdf')}}>

                            <li>- Improve child care at a Children's home: partner with second hand store, grocery store and pharmacy</li>
                            
                            </div>
                            <div  className= 'fileitem'onClick={()=>{downloadFile("MUMC Improve Care at Children's home.pdf")}}>
                            <li>-Two groups planned a healthy kitchen for the local school: partnering with organic farmers and staffed by the school students</li>

                            </div>
                            <li>-Build a home for parentless children: co-ordinate with builder's, interior designers, and NGOs</li>
                        </ul>
                        
                    </li>
                    <li className='heading'>
                        Hawaii
                        <ul className='list content'>
                            <li>
                                <a href='https://sites.google.com/k12.hi.us/khpesgll/' target="_blank" rel="noopener noreferrer">
                                    - Ka’ū Dream - A Model for Building Community Resilience
                                </a>
                            </li>
                            <li>
                                <a href='https://www.hec.org/education' target="_blank" rel="noopener noreferrer">
                                    - HEC - Hawai’i Executive Collaborative
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className='heading'>
                        Sri Lanka
                        <ul className='list content'>
                            <li>
                            - Partnered with the Association for Human Resource Professionals of Sri Lanka. 
                            </li>
                            <li>
                            - Thirty Leadership Academies are scheduled and funding is available for Sustainable Impact Projects. More information coming soon!
                            </li>
                        </ul>
                    </li>
                    <li className='heading'>
                        Coming Soon ...
                        <ul className='list content'>
                            <li>- Albania</li>
                            <li>- Bulgaria</li>
                        </ul>
                    </li>
                </ul>
            
            </div>
        </div>
    );
};

export default CurrentProjects;
