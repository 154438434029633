import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function UpdateBodyBackground() {
    const location = useLocation();

    useEffect(() => {
        // Function to update the body class based on current path
        function updateBodyBackground(path) {
            // Strip the leading '/' from the path to use it as a class name
            const className = path.substring(1);
            

            // Reset all classes first
            document.body.className = '';

            // Add the new class based on the path
            if (className) { // Ensure className is not empty
                document.body.classList.add(className);
            }
        }

        // Call the function with the current pathname
        updateBodyBackground(location.pathname);

        // Cleanup function to remove class when component unmounts or location changes
        return () => {
            document.body.className = ''; // Reset the background when the component unmounts or path changes
        };
    }, [location.pathname]); // Depend on location.pathname to rerun the effect when it changes
}

export default UpdateBodyBackground;
