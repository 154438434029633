import React from 'react';
import './quote.scss';

const Quote = (props) => {
  const {title, description } = props;


  return (
    <div className="heros">
      <p className='heading'>{title}</p>
      <p className='description'>{description}</p>
      {/* <Button label="Explore More" onClick={handleClick} type="arrow" /> */}
    </div>
  );
}

export default Quote;