import React from 'react'
import './VideoPlayer.scss'

const VideoPlayer = (props) => {
    const { src } = props;

    return (
        <div className="video-container">
            <div className='video'>
                <iframe
                    title='video'
                    src={src}
                    width='100%'
                    height='100%'
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    );
}



export default VideoPlayer;
