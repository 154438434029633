import React,{useEffect} from 'react';
//import { collection, getDocs} from 'firebase/firestore';
//import { db } from '../conf/Firebase';
import HeroSection from '../components/HeroSection/HeroSection';
import SectionTitle from '../components/SectionTitle/SectionTitle';
//import Project from '../components/Project/Project';
import './Projects.scss';
import Goal from '../components/Goal/Goal';
import PdfViewer from '../components/vidUrl/PdfViewer';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/swiper-bundle.css";
import VideoPlayer from '../components/VideoPlayer/videoPlayer';
import UpdateBodyBackground from '../utils/UpdateBackground';
import { Helmet } from 'react-helmet';


const Projects = () => {
  UpdateBodyBackground()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cards = [
    {
      title: 'The Need',
      description: 'Local organizations are supporting communities in need each and every day. However the need is greater than the hands and resources that can be applied. New solutions and fresh ideas from passionate young community members are needed to create impact in communities across the continent with fresh eyes and new ideas.',
      image: 'need'
    },
    {
      title: 'What We Know',
      description: 'A thriving community is built upon a foundation of learning, leadership, and collaboration. We recognize that individual knowledge and experiences, when combined with social and community-based learning, are pivotal in achieving sustainability and resilience.',
      image: 'know',
    },
    {
      title: 'What We Do',
      description: 'Our leadership academy is an integrated, multidimensional approach to creating sustainable communities, youth development, and community well-being. The program is designed to develop youth as citizen leaders through a 2-day program delivered peer to peer and a sustainable development project led by youth.',
      image: 'do'
    }
  ]

  return (
    <div className='page projects'>
      <Helmet>
        <title >Leadership Academy - Global Learning Lab</title>
        <meta
          name="description"
          content="Explore our Leadership Academy program designed to empower youth and existing leaders with skills and knowledge to create sustainable communities and positive social impact."
        />
        <link rel='canonical' href='https://globallearninglab.org/leadershipacademy'/>
      </Helmet>


        {/* ------------- Hero Section -------------*/}

        <HeroSection
        title='Leadership Academy'
        description=''
        />


        {/* ------------- Projects Section -------------*/}
        {/* <SectionTitle 
          title='Portfolio of Completed Projects' 
          description='Dive into our world of exploration, where we foster a sense of global curiosity and nurture minds to embrace the 
          power of global learning on a journey without boundaries.'
        /> */}
      <div  className='videocontainer'>
        <div className='video' >

        <VideoPlayer src="https://drive.google.com/file/d/1v0C_OWBCGhCrBLQUJN85o4nUgcvhPzq9/preview" />
        </div>

      </div>

        <SectionTitle 
          type='primary'
          title='' 
          style={{fontSize:"50px"}}
          description={<span style={{fontSize:"35px"}}>Investing in the potential of youth, emerging leaders, and existing leaders can benefit our shared future.</span>}
          />

        <div id='goal' className="container goals">
          <div className="goals">
            {cards.map((card, index) => (
              <Goal
                title={card.title}
                description={card.description}
                image={card.image}
              />
            ))}
          </div>
        </div>

        <div className="swiper-mobile-container project-route">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate:0,
              stretch:0,
              depth:100,
              modifier:2.5
            }}
            modules={[Pagination, Navigation]}
            // spaceBetween={50}
            scrollbar={{ draggable: true }}
            pagination={{el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            className='swiper-container'
            style={{
              "--swiper-pagination-color": "#357F90"}}
          >
            {cards.map((card, index) => (
              <SwiperSlide className='swiper-slide'>
                <Goal
                  title={card.title}
                  description={card.description}
                  image={card.image}
                />
              </SwiperSlide>
              ))}

            <div className="slide-controller">
              <div className="swiper-button-next" style={{ color: '#357F90' }}></div>
              <div className="swiper-button-prev" style={{ color: '#357F90' }}></div>
              <div className="swiper-pagination">

              </div>
            </div>
          </Swiper>
        </div>

        <div className="container outcome">
          <div className="row">
            <img src={'/images/individual.svg'} alt='logo' className='image imageonly'/>
            <div className="outcomes">
              <p className="title">Individual</p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants see themselves as leaders. When youth see themselves as 
                    leaders they are more likely to engage in leadership development & become inspired to 
                    participate in community change initiative. </p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants grow in their abilities to be a leader, particularly in regard to 	LEADING SELF, LEADING WITH OTHERS, and 
                    CHANGING YOUR WORLD indicators.</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants see themselves as change agents and have concrete ideas about how to use their leadership skills
                    to help make positive impacts in their community 	</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <img src={'/images/group.svg'} alt='logo' className='mobile image imageonly'/>
            <div className="outcomes">
              <p className="title">Community</p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Better education</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Higher quality of life</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Healthier families</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>More sustainable communities</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Community becomes a model to inform the leadership and development field more broadly</p>
                </li>
              </ul>
            </div>
            <img src={'/images/group.svg'} alt='logo' className='desktop image imageonly'/>
          </div>
        </div>

        <div className="container outcome youth-leadership">
          <div className="row collage">
            <img src={'/images/leadership.png'} alt='logo' className='image collage'/>
            <div className="outcomes">
              <p className="title">How it Works </p>
              <ul>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>Participants will be supported/sponsored by an organization as they carry out their <a className='link' href='/resources'>
                    sustainable impact plan (SIP)</a></p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>The Leadership Academy requires a 4-day train-the-trainer program (TTT) that teaches future facilitators the leadership modules based on applied research, best practices and adapted content, while allowing them to reflect on how they would uniquely facilitate these modules. This TTT model allows for localized peer to peer training that is sustainable and scalable</p>
                </li>
                <li className='outcome'>
                  <img src={'/icons/eclipse.svg'} alt='logo' />
                  <p>After completing the TTT, Leaders will be certified to train the 2-day Leadership Academy. Trainers can become Master Trainers, Country Trainers and Global Trainers</p>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div className='container projects'>
        <PdfViewer></PdfViewer>
        </div>

        {/* <div className="container projects">
          {projects.map((project, index) => (
            <Project 
            isEven={(index)%2}
            isFirst={index===0}
            isLast={index === projects.length -1}
            image={project.image}
            title={project.title}
            description={project.description}
            impacts={project.impacts}
            />
          ))} */}
          {/* <div className="styled-container first">
            <div className="project">
              <div className="images">
                <div className='glob'></div>
                <div className="img">
                  <img src="/images/projects/project1.png" alt="" className="picture" />
                </div>
              </div>
              <div className="content">
                <p className="title">Exploring Sustainable Urban Design</p>
                <p className="description">Welcome to our project page dedicated to the exploration of sustainable urban design. In this space, we delve into innovative approaches and solutions for creating environmentally-friendly, socially inclusive, and economically viable urban environments. </p>
                <p className="subtitle">Impact</p>
                <ul>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="styled-container even">
            <div className="project even">
              <div className="images mobile">
                <div className='glob'></div>
                <div className="img">
                  <img src="/images/projects/project1.png" alt="" className="picture" />
                </div>
              </div>
              <div className="content">
                <p className="title">Exploring Sustainable Urban Design</p>
                <p className="description">Welcome to our project page dedicated to the exploration of sustainable urban design. In this space, we delve into innovative approaches and solutions for creating environmentally-friendly, socially inclusive, and economically viable urban environments. </p>
                <p className="subtitle">Impact</p>
                <ul>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                </ul>
              </div>
              <div className="images desktop">
                <div className='glob'></div>
                <div className="img">
                  <img src="/images/projects/project1.png" alt="" className="picture" />
                </div>
              </div>
            </div>
          </div>
          <div className="styled-container last">
            <div className="project">
              <div className="images">
                <div className='glob'></div>
                <div className="img">
                  <img src="/images/projects/project1.png" alt="" className="picture" />
                </div>
              </div>
              <div className="content">
                <p className="title">Exploring Sustainable Urban Design</p>
                <p className="description">Welcome to our project page dedicated to the exploration of sustainable urban design. In this space, we delve into innovative approaches and solutions for creating environmentally-friendly, socially inclusive, and economically viable urban environments. </p>
                <p className="subtitle">Impact</p>
                <ul>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                  <li>
                      <img src="/icons/eclipse.svg" alt="" />
                      <p className="point">Inspiration: Encouraging a new way of thinking about urban development that values sustainability.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        {/* </div> */}
        
    </div>
  );
}

export default Projects;

// const projects = [
//   {
//     title: 'Exploring Sustainable Urban Design',
//     description: 'Welcome to our project page dedicated to the exploration of sustainable urban design. In this space, we delve into innovative approaches and solutions for creating environmentally-friendly, socially inclusive, and economically viable urban environments. ',
//     image: 'project1.png',
//     impacts: [
//       'Inspiration: Encouraging a new way of thinking about urban development that values sustainability',
//       'Informed Decision-Making: Providing valuable insights for urban planners, policymakers, and developers.',
//       'Sustainable Cities: Contributing to the global effort to create more sustainable and livable cities.'
//     ]
//   },
//   {
//     title: "Shaping Tomorrow's Minds",
//     description: 'Here, we delve into the dynamic world of educational practices and technologies that are transforming the way we learn and teach. Our mission is to inform, inspire, and drive positive change in the realm of education. We showcase the most recent trends, case studies, and research in teaching and learning to foster an environment that empowers students and educators alike. ',
//     image: 'project2.png',
//     impacts: [
//       'Empowered Educators: Equipping teachers and instructors with tools and knowledge to enhance their teaching methods.',
//       'Student Success: Contributing to improved learning outcomes and overall academic performance.',
//       'Lifelong Learning Culture: Promoting a culture of continuous learning and skill development.'
//     ]
//   },
//   {
//     title: 'Exploring Sustainable Urban Design',
//     description: 'Welcome to our project page dedicated to the exploration of sustainable urban design. In this space, we delve into innovative approaches and solutions for creating environmentally-friendly, socially inclusive, and economically viable urban environments. ',
//     image: 'project1.png',
//     impacts: [
//       'Inspiration: Encouraging a new way of thinking about urban development that values sustainability',
//       'Inspiration: Encouraging a new way of thinking about urban development that values sustainability',
//       'Inspiration: Encouraging a new way of thinking about urban development that values sustainability'
//     ]
//   },
// ]